import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import EbookHero from "../components/ebook-hero"
import SEO from "../components/seo"
import Container from "../components/container"
import WomanReadingTabletImg from "../images/ebooks/woman-reading-tablet.png"
import theme from "../consts/theme"
import { getEbooks } from "../utils"

const SC = {}

const EbookAutodidatismoParaIniciantes = () => {
  const ebook = getEbooks("Autodidatismo para iniciantes")

  return (
    <Layout>
      <SEO
        title={`[eBook] ${ebook.title}`}
        description={ebook.description}
        canonical={`${ebook.url.substring(1)}`}
        author="Apetrecho Digital"
      />
      <EbookHero
        cover={ebook.cover}
        title={`Conheça os 5 passos
          <br />
          para se tornar
          <br />
          autodidata`}
        description={`Descubra como a <b>simples mudança de
        <br />
        hábitos</b> e o <b>desenvolvimento de
        <br />
        habilidades certas</b> vai fazer de você um
        <br />
        autodidata!`}
        titlePrimaryColor="#fff"
        titleSecondaryColor="#fff"
        descriptionColor="#fff"
        leftBgColor={theme.colors.SECONDARY_GREEN}
        rightBgColor={theme.colors.SECONDARY_GREEN}
        ebookTag={ebook.mailchimpTag}
        inputBgColor="#FFFFFF55"
      />
      <SC.Content
        isFlex
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
      >
        <SC.ContentTextWrapper>
          <SC.ContentTitle>Por que ser autodidata?</SC.ContentTitle>
          <SC.ContentItems>
            <ul>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>conquistar a independência da sua educação</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>desenvolver seu potencial máximo</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>estimular sua criatividade</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>ser mais eficiente</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>adquirir versatilidade profissional</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>ganhar vantagem competitiva</span>
              </li>
            </ul>
          </SC.ContentItems>
        </SC.ContentTextWrapper>
        <SC.ContentImg
          src={WomanReadingTabletImg}
          alt="Mulher sentada mexendo em tablet"
        />
      </SC.Content>
      <SC.Quotation>
        <p>
          "O analfabeto do século XXI não será aquele que não consegue ler e
          escrever, mas aquele que não consegue aprender, desaprender e
          reaprender."
        </p>
        <span>Alvin Toffler</span>
      </SC.Quotation>
      <SC.ContentLearning
        isFlex
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <SC.ContentTitle>Neste eBook você vai aprender:</SC.ContentTitle>
        <SC.LearningItems>
          <SC.LearningItem>
            <SC.LearningItemNumber>1</SC.LearningItemNumber>
            <span>Autoconhecimento:</span>
            <p>descubra seus interesses</p>
          </SC.LearningItem>
          <SC.LearningItem>
            <SC.LearningItemNumber>2</SC.LearningItemNumber>
            <span>Hábitos:</span>
            <p>aprenda a ser curioso e proativo</p>
          </SC.LearningItem>
          <SC.LearningItem>
            <SC.LearningItemNumber>3</SC.LearningItemNumber>
            <span>Persistência:</span>
            <p>não desista na primeira dificuldade</p>
          </SC.LearningItem>
          <SC.LearningItem>
            <SC.LearningItemNumber>4</SC.LearningItemNumber>
            <span>Ferramentas:</span>
            <p>encontre os recursos adequados</p>
          </SC.LearningItem>
          <SC.LearningItem>
            <SC.LearningItemNumber>5</SC.LearningItemNumber>
            <span>Direcionamento:</span>
            <p>avalie seu desempenho</p>
          </SC.LearningItem>
        </SC.LearningItems>
      </SC.ContentLearning>
    </Layout>
  )
}

SC.Content = styled(Container)`
  padding-top: 40px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
  }
`

SC.ContentTitle = styled.h2`
  color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
  font-size: 32px;
  font-weight: 700;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 26px;
    text-align: center;
  }
`

SC.ContentTextWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 0 80px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
    margin-bottom: -140px;
  }
`

SC.ContentItems = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
    margin-top: 40px;

    li {
      span {
        font-family: ${({ theme }) => theme.fonts.NUNITO};
        font-size: 22px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
          font-size: 18px;
        }
      }

      svg {
        margin-right: 5px;
        height: 14px;
      }
    }
  }
`

SC.ContentImg = styled.img`
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 400px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    position: relative;
  }
`

SC.Quotation = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colors.SECONDARY_GREEN};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 40px 25px;
  }

  p {
    font-size: 22px;
    color: #fff;
    width: 800px;
    text-align: center;
    font-style: italic;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      width: 100%;
    }
  }

  span {
    font-family: ${({ theme }) => theme.fonts.NUNITO};
    font-weight: bold;
    color: #fff;
  }
`

SC.ContentLearning = styled(SC.Content)`
  margin-top: 50px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 35px;
    padding: 0 25px;
  }
`

SC.LearningItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 65%;
  margin: 60px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.LearningItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 40px;
  width: 200px;
  height: 200px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    margin: 0 auto;
  }

  span {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
    color: #000;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      font-size: 20px;
    }
  }

  p {
    margin-top: 6px;
  }
`

SC.LearningItemNumber = styled.div`
  background-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
  width: 70px;
  height: 70px;
  border-radius: ${({ theme }) => theme.sizes.ROUNDED_BORDER_RADIUS};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #fff;
  font-weight: 800;
`

export default EbookAutodidatismoParaIniciantes
