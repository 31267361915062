import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import Container from "./container"

const SC = {}

const EbookHero = ({
  cover,
  title,
  description,
  titlePrimaryColor,
  titleSecondaryColor,
  descriptionColor,
  leftBgColor,
  rightBgColor,
  hotmartUrl,
}) => {
  const handleClick = () => {
    window.open(hotmartUrl)
  }

  return (
    <SC.Hero bgColor={leftBgColor}>
      <SC.Container
        isFlex
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
      >
        <SC.LeftWrapper>
          <SC.Heading
            primaryColor={titlePrimaryColor}
            secondaryColor={titleSecondaryColor}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <SC.Description
            color={descriptionColor}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <SC.Submit type="submit" onClick={handleClick}>
            <span>ACESSAR</span>
            <FontAwesomeIcon icon="download" color="#fff" />
          </SC.Submit>
        </SC.LeftWrapper>
        <SC.RightWrapper bgColor={rightBgColor}>
          <SC.EbookCover alt="Capa eBook" src={cover} />
        </SC.RightWrapper>
      </SC.Container>
    </SC.Hero>
  )
}
SC.Hero = styled.section`
  height: 630px;
  overflow: hidden;
  background-color: ${({ bgColor }) => bgColor};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: auto;
    padding: 25px 0;
    margin-top: 50px;
  }
`

SC.Container = styled(Container)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
    padding: 0 25px;
  }
`

SC.LeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 80px;
  width: 70vw;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    padding: 0;
  }
`

SC.RightWrapper = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  transform: skewX(-30deg);
  position: relative;
  margin-right: -30vw;
  height: 100vw;
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: auto;
    margin: 0;
    background-color: transparent;
  }
`

SC.Heading = styled.h1`
  font-weight: 800;
  font-size: 46px;
  color: ${({ primaryColor }) => primaryColor};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 26px;
    width: 100%;
  }

  span {
    color: ${({ secondaryColor }) => secondaryColor};
  }
`

SC.Description = styled.p`
  color: ${({ color }) => color};
  font-size: 22px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 18px;

    br {
      display: none;
    }
  }
`

SC.InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  margin: 15px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border: none;
  outline: none;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  font-weight: 500;
  color: #fff;
`

SC.Submit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.GREEN_1};
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  span {
    margin-right: 15px;
  }
`

SC.EbookCover = styled.img`
  transform: skewX(30deg);
  height: 500px;
  margin-top: 50px;
  margin-left: 50px;
  box-shadow: 10px 10px 10px 5px rgb(0 0 0 / 50%);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: 260px;
    margin: 25px auto;
  }
`

SC.ServerResponse = styled.span`
  display: block;
  margin-top: 20px;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
`

export default EbookHero
